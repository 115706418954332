import Axios from "axios";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";

const instance = Axios.create();
const axios = setupCache(instance, {
  storage: buildWebStorage(localStorage, "axios-cache:"),
  methods: ["delete", "get", "post", "options", "patch"],
});

export { axios };
